@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.product-card-from-price {
	&__price {
		.amount {
			color: $black;

			&__prefix,
			&__suffix {
				font-family: var(--font-family-bold);
				font-size: var(--font-size-regular);
				color: $black;

				@include gridle_state(md) {
					font-size: var(--font-size-regular);
				}
			}

			&__value {
				font-family: var(--font-family-bold);
				font-size: var(--font-size-xl);
			}
		}
	}

	&__info {
		display: inline-flex;
		text-transform: lowercase;
	}

	&__flight {
		margin-left: 3px;
	}
}
