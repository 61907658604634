@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.product-name-with-category {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	unicode-bidi: plaintext;

	// force rtl for product name
	// @see https://github.com/elchininet/postcss-rtlcss?tab=readme-ov-file#rtlrawcss

	/*rtl:raw:
	   direction: ltr;
	   justify-content: flex-end;*/

	&__name {
		margin-right: 10px;
	}

	&__category {
		.category {
			&__item {
				&.icon {
					height: 15px;
					width: 15px;
				}
			}

			&-trident {
				&__item {
					&.icon {
						height: 20px;
						width: 30px;
					}
				}
			}
		}
	}
}
